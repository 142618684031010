import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Badge,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
// MSAL
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "../authConfig";
import ProductCard from "../pages/homePage/ProductCard";
import UserInfo from "../hooks/userInfo";

// Images
import brandLogo from "../assets/images/horse-mq-logo.svg";
import cartIcon from "../assets/images/cart-icon.svg";

import { retrieveProducts } from "../redux/slice/home/ProductsSlice";
import { useAppDispatch, useTypedSelector, RootState } from "../redux/store";
import { retrievePageConfigurations } from "../redux/slice/PageConfigurationsSlice";
import {
  collectCartItemsFromCookies,
  retrieveNavigationHeader,
  setCartValues,
} from "../redux/slice/NavigationSlice";
import { TPageConfigurations } from "../models/PageConfigurationsModel";
import { TVariants } from "../models/product/productDetailModel";
import { TCartItem } from "../pages/productDetail/ProductKitInfo";
import {
  GetAllMyHorses,
  THorseInfo,
} from "../redux/slice/dashboard/GetAllMyHorsesSlice";
import { retrieveCheckUserAdminStatus } from "../redux/slice/reports/admin/ReportsAdminSlice";
import { GetCustomerInfoById } from "../redux/slice/customer-info/CustomerAddressSlice";
import { fetchRoles } from "../redux/slice/roles/rolesSlice";
import RoleBasedComponent from "./re-usable/RoleBasedComponent"

const Header = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();
  const navbarRef = useRef<HTMLDivElement>(null);
  let [cart] = React.useState(searchParams.get("cart"));
  const { CartItems } = useTypedSelector(
    (state: RootState) => state.CartValues
  );
   //get roles
   
   const {RolesData,RolesLoading}=useTypedSelector((state:RootState)=>state
  .Roles);


  const { CustomerInfoByIDData } = useTypedSelector(
    (state: RootState) => state.GetCustomerInfoById
  );
  const { shopifyCustomerId } = UserInfo(); // Access the firstName from the UserInfo component
  const [count, setCount] = useState<number | null>(null);
  const [isCartValueUpdated, setIsCartValueUpdated] = useState<boolean>(false);
  const [isMenuShow, setIsMenuShow] = useState<boolean>(false);
  const { GetMyHorsesData, GetMyHorsesLoading } = useTypedSelector(
    (state: RootState) => state.GetAllMyHorses
  );
  const header = document.querySelector(".navbar-light");
  const selectContainers = useRef<NodeListOf<Element> | null>(null);

  // const [searchParams] = useSearchParams();
  const initialSSO = JSON.parse(searchParams.get("initiateSSO") as string);
  const [isLoading, setIsLoading] = useState<boolean | undefined>();
  

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          console.log("Login successful:", "success");
        } else {
          console.log("No redirect response found");
          if (initialSSO) {
            console.log("Initiating login redirect");
            instance.loginRedirect(loginRequest).catch((error) => {
              console.error("Login redirect error:", error);
            });
          }
        }
      })
      .catch((error) => {
        console.error("Redirect handling error:", error);
      });
  }, [dispatch, initialSSO]);

  useEffect(() => {
    if (cart === "null") {
      // // If the cart is null, set the CartItems to an empty array or handle as needed
      Cookies.remove("cartItems");
    } else {
      // When the component mounts, collect cart items from cookies
      dispatch(collectCartItemsFromCookies());
    }
  }, [dispatch, cart]);

  useEffect(() => {
    if (shopifyCustomerId) {
      let HorseInfo: THorseInfo = {
        customerId: Number(shopifyCustomerId),
        page: 1,
      };
      dispatch(GetAllMyHorses(HorseInfo));
      dispatch(retrieveCheckUserAdminStatus(shopifyCustomerId));
      dispatch(GetCustomerInfoById(shopifyCustomerId));
    }
  }, [dispatch, shopifyCustomerId]);

  useEffect(() => {
    // Ensure CartItems is an array before proceeding
    if (
      Array.isArray(CartItems) &&
      CartItems.length > 0 &&
      !CartItems.every((item) => item === null)
    ) {
      const totalCount = CartItems.reduce((total, item) => {
        if (item && item.quantity !== null) {
          // Set Shake animation when cart vlaue updtaed
          setIsCartValueUpdated(true);
          setTimeout(() => setIsCartValueUpdated(false), 1000);
          // Check if item is not null and has a quantity
          return total + item.quantity;
        }
        return total; // If item is null or doesn't have quantity, return current total
      }, 0);
      setCount(totalCount);
    } else {
      setCount(null);
    }
  }, [CartItems]);
  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  // Products API Selector
  const { ProductsData } = useTypedSelector(
    (state: RootState) => state.Products
  );

  // Navigation API Selector
  const { NavigationHeaderData } = useTypedSelector(
    (state: RootState) => state.Navigation
  );

  // PageConfigurations  API Selector
  const { PageConfigurationsData } = useTypedSelector(
    (state: RootState) => state.PageConfigurations
  );

  const { instance, inProgress } = useMsal();

  // MSAL Login
  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  // MSAL Logout
  const handleLogoutRedirect = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(
        process.env.REACT_APP_Shopify_Client_Id as string
      ),
      postLogoutRedirectUri: process.env.REACT_APP_LOL_LOGOUT_URL as string,
    };
    instance.logoutRedirect(logoutRequest);
    // window.location.assign(process.env.REACT_APP_LOL_LOGOUT_URL as string);
  };

  // Close menu when clicked outside
  const handleDocumentClick = (e: MouseEvent) => {
    const target = e.target as Node;
    // Check if the clicked element is a select control or its descendant
    if (
      navbarRef.current &&
      !navbarRef.current.contains(target) &&
      (!selectContainers.current ||
        Array.from(selectContainers.current).every(
          (container) => !container.contains(target)
        ))
    ) {
      setIsMenuShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    // Assuming selectContainer is a reference to your select container element
    const selectContainer = document.querySelector(".basic-single");
    selectContainer?.addEventListener("click", () => {
      setIsMenuShow(false);
    });
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      // Assuming selectContainer is a reference to your select container element
      const selectContainer = document.querySelector(".select__control");
      selectContainer?.removeEventListener("click", () => {
        setIsMenuShow(false);
      });
    };
  }, []);

  useEffect(() => {
    dispatch(retrieveProducts());
    dispatch(retrieveNavigationHeader());
    dispatch(retrievePageConfigurations());
  }, [dispatch]);

  return (
    <Navbar
      ref={navbarRef}
      collapseOnSelect
      expand="lg"
      expanded={isMenuShow}
      className="bg-body-tertiary mq-nav fixed-top"
    >
      <Container>
        <Link
          className="navbar-brand"
          to="/"
          onClick={() => setIsMenuShow(false)}
        >
          {PageConfigurationsData &&
            PageConfigurationsData.map(
              (menuItem: TPageConfigurations, index: number) => {
                return (
                  <img
                    alt={menuItem.headerLogoAltText}
                    src={menuItem.headerLogo}
                    className="img-fluid"
                    key={index}
                  />
                );
              }
            )}
        </Link>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="btn btn-trtiary-icon "
          onClick={() => setIsMenuShow(!isMenuShow)}
        />
        <span className="d-block d-lg-none">
          <Link
            to={"/cart"}
            className={`btn btn-primary-icon cart ${
              isCartValueUpdated ? "shake" : ""
            }`}
            type="submit"
          >
            <img
              alt="Brand logo"
              src={cartIcon}
              className="img-fluid"
              style={{ height: "24px", width: "24px" }}
            />
            {count !== null && (
              <Badge bg="white" className="cart-count">
                {count > 99 ? "99+" : count}
              </Badge>
            )}
          </Link>
        </span>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav-links primary-menu">
            {NavigationHeaderData &&
              NavigationHeaderData.map((item, index) => {
                return item.title === "My Stable" ? (
                  // If Navigation item is My Stable
                  <AuthenticatedTemplate key={index}>
                    <Nav.Link
                      as={Link}
                      to={item.redirectURL}
                      onClick={() => setIsMenuShow(false)}
                    >
                      {item.title}
                    </Nav.Link>
                  </AuthenticatedTemplate>
                ) : item.isShop ? (
                  // If Navigation item is Shop show products
                  <NavDropdown
                    title={item.title}
                    key={index}
                    id="collasible-nav-dropdown"
                    className="product-card-menu"
                  >
                    {item.products &&
                      item.products?.map((item, index) => {
                        return (
                          <NavDropdown.Item key={index} as={"div"}>
                            <ProductCard
                              cardColorType="white"
                              cardButtonType="btn-link"
                              data={item}
                              isVisible={true}
                              header={true}
                              onIsMenuShow={setIsMenuShow}
                              hasLinks={true}
                            />
                          </NavDropdown.Item>
                        );
                      })}
                  </NavDropdown>
                ) : item.childrens !== null ? (
                  // If Navigation item No Child
                  <NavDropdown
                    title={item.title}
                    key={index}
                    id="collasible-nav-dropdown"
                    className="user-dropdown-menu"
                  >
                    {item.childrens &&
                      item.childrens?.map((item, index) => {
                        return (
                          <NavDropdown.Item
                            as={Link}
                            key={index}
                            // <Link
                            to={item.redirectURL}
                            onClick={() => {
                              setIsMenuShow(false);
                            }}
                          >
                            {item.title}
                          </NavDropdown.Item>
                        );
                      })}
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    as={Link}
                    onClick={() => setIsMenuShow(false)}
                    to={item.redirectURL}
                    key={index}
                  >
                    {item.title}
                  </Nav.Link>
                );
              })}
          </Nav>
          <Nav className="nav-links-right">
            <UnauthenticatedTemplate>
              <Button
                variant="secondary"
                type="submit"
                onClick={handleLoginRedirect}
                className=""
              >
                Login
              </Button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Dropdown className="user-dropdown-menu user-status">
                <Dropdown.Toggle
                  variant="secondray"
                  id="dropdown-basic"
                  className="text-capitalize"
                >
                  {CustomerInfoByIDData[0]?.firstName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    className="dropdown-item"
                    to="/profile"
                    onClick={() => setIsMenuShow(false)}
                  >
                    Profile
                  </Dropdown.Item>
                  <RoleBasedComponent allowedRoles={[]}>
                  <Dropdown.Item
                      as={Link}
                      className="dropdown-item"
                      to="/reports-admin"
                      onClick={() => setIsMenuShow(false)}
                    >
                     Report Admin
                    </Dropdown.Item>
                  </RoleBasedComponent>
                  <Dropdown.Item onClick={handleLogoutRedirect}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </AuthenticatedTemplate>
            <span className="d-none d-lg-block">
              <Link
                to={"/cart"}
                className={`btn btn-primary-icon cart ${
                  isCartValueUpdated ? "shake" : ""
                }`}
                type="submit"
              >
                <img
                  alt="Cart icon"
                  src={cartIcon}
                  className="img-fluid"
                  style={{ height: "30px", width: "30px" }}
                />
                {count !== null && (
                  <Badge bg="white" className="cart-count">
                    {count > 99 ? "99+" : count}
                  </Badge>
                )}
              </Link>
            </span>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
