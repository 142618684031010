import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { fetchRoles, clearRoles } from "../../redux/slice/roles/rolesSlice"; // Import actions

interface RoleBasedComponentProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

const RoleBasedComponent: React.FC<RoleBasedComponentProps> = ({ allowedRoles, children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userRoles = useSelector((state: RootState) => state.Roles.RolesData);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false); // Track if roles are currently being fetched

  // Fetch roles if not present in Redux
  useEffect(() => {
    if (userRoles.length === 0 && !isFetchingRoles) {
      setIsFetchingRoles(true);
      dispatch(fetchRoles())
        .then(() => {
          setIsFetchingRoles(false);
        })
        .catch((error) => {
          console.error("Error fetching roles:", error);
          setIsFetchingRoles(false);
        });
    }
  }, [dispatch, userRoles, isFetchingRoles]); // Ensure we only fetch if no roles are present


  // Check if the user has access to the component based on allowed roles
  const hasAccess =
    userRoles.length > 0 &&
    (allowedRoles.length === 0 || allowedRoles.some((role) => userRoles.includes(role)));


  if (!hasAccess) {
    return null; // If no access, hide the component
  }

  return <>{children}</>; // Render the children if access is granted
};

export default RoleBasedComponent;
