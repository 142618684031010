import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import rolesService from "../../../services/roles/rolesService"; // Update with your actual service path

// Define the Role state type
export type TRoleInitialStateType = {
  RolesData: string[]; // Array of roles
  RolesLoading: boolean;
  RolesError:
    | "Bad Request"
    | "Unauthorized"
    | "Not Found"
    | "Internal Server Error"
    | undefined;
};

// Initial state
const initialState: TRoleInitialStateType = {
  RolesData: [],
  RolesLoading: false,
  RolesError: undefined,
};

// Thunk for fetching roles from API
export const fetchRoles = createAsyncThunk("roles/fetch", async () => {
  const response: AxiosResponse<{ roles: string[] }> = await rolesService.getRoles();
  return response.data.roles;
});

// Slice for roles management
const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearRoles: (state) => {
      state.RolesData = [];
      state.RolesLoading = false;
      state.RolesError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.RolesLoading = true;
        state.RolesError = undefined;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.RolesData = action.payload;
        state.RolesLoading = false;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.RolesLoading = false;
        state.RolesError = "Internal Server Error";
      });
  },
});

// Export actions
export const { clearRoles } = rolesSlice.actions;

// Export reducer
export default rolesSlice.reducer;
