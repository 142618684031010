import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import PageLoader from "../components/re-usable/PageLoader";
import { useTypedSelector, RootState, AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";
import { fetchRoles } from "../redux/slice/roles/rolesSlice"; // Action to fetch roles from API

interface ProtectedRouteProps {
  component: React.JSX.Element;
  hasRolesRequired?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, hasRolesRequired = false }) => {
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isUser, setIsUser] = useState<boolean | null>(null);
  const rolesFromStore = useTypedSelector((state: RootState) => state.Roles.RolesData);
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);

  // Check for user authentication
  useEffect(() => {
    if (accounts.length > 0) {
      setIsUser(true);
    } else {
      instance
        .handleRedirectPromise()
        .then((response) => {
          if (response !== null && response.account !== null) {
            setIsUser(true);
          } else {
            setIsUser(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setIsUser(false);
        });
    }
  }, [accounts, instance]);

  // Fetch roles from the Redux store or dispatch the action to fetch them if not present
  useEffect(() => {
    if (!rolesFromStore || rolesFromStore.length === 0) {
      dispatch(fetchRoles()) // Dispatch action to fetch roles from API
        .unwrap()
        .then(() => {
          setIsLoadingRoles(false); // Roles are successfully loaded
        })
        .catch((error) => {
          console.error("Failed to fetch roles", error);
          setIsLoadingRoles(false); // Even in error, set loading to false
        });
    } else {
      setIsLoadingRoles(false); // Roles are already loaded
    }
  }, [rolesFromStore, dispatch]);

  // Handle redirection based on roles and user authentication
  useEffect(() => {
    if (isUser === false) {
      console.log("if");
      navigate("/prohibited");
    } else if (isUser === true && hasRolesRequired && !isLoadingRoles && rolesFromStore.length === 0) {
      console.log("else");
      navigate("/prohibited");
    }
  }, [isUser, navigate, hasRolesRequired, rolesFromStore, isLoadingRoles]);

  // While roles are loading or user authentication status is uncertain, show loader
  if (isUser === null || isLoadingRoles) {
    return <PageLoader />;
  }

  // After roles are loaded, render the protected route component if user is authorized
  return isUser && (!hasRolesRequired || rolesFromStore.length > 0) ? component : null;
};

export default ProtectedRoute;
